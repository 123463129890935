import styled, { css } from 'styled-components'
import { colors } from '@/styles/color'
import { appear } from '@/styles/animation'
import { H2 } from '@/styles/text'
import { center, mediaMax, mediaMin } from '@/styles/layout'
import easing from '@/styles/easing'
import { column } from '@/styles/grid'

export const ImageContainer = styled.div`
  padding-top: 130%;
  background-color: ${colors.moss.dark};
  position: relative;
  overflow: hidden;
`

export const imageStyle = css`
  object-fit: cover;
  position: absolute;
  ${center};
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  transition: transform 0.75s ${easing.inOutStrong};
  transform-origin: left top;
`

export const ProjectTeaserContainer = styled.div`
  ${column(3, 1.5)};
  margin-bottom: 60px;
  @media (pointer: fine) {
    &:hover {
      ${ImageContainer} > img {
        transform: scale(1.1) translate3d(-50%, -50%, 0);
      }
    }
  }
  ${mediaMin(
    'medium',
    css`
      &:nth-of-type(even) {
        ${column(3, 1)};
      }
    `
  )}
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )}
`

export const Teaser = styled.a`
  display: block;
  color: ${colors.white};
  text-decoration: none;
  ${props =>
    appear({
      startTransform: `translateY(100px)`,
      duration: 0.5 + props.index * 0.2,
      delay: 0.25 + props.index * 0.1,
    })};
`

export const ProjectTitle = styled(H2)`
  font-size: 26px;
  margin-bottom: 6px;
`

export const ProjectSubtitle = styled.div`
  font-size: 16px;
  color: ${colors.grey.base};
`
