import { buttonStyle, CTAContainer, CTAColumn, Headline, IconContainer, Subline } from './styles'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import Button from '@/components/common/Button'
import PaperplaneIcon from './assets/paperplane.svg'
import Linky from '@/components/common/Linky'
import Markdown from 'markdown-to-jsx'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const CallToAction = ({ module }) => {
  const moduleInViewport = useViewport()
  const { headline, subline, button } = module
  return (
    <CTAContainer>
      <MaxWidth>
        <Row>
          <CTAColumn>
            <IconContainer inViewport={moduleInViewport}>
              <PaperplaneIcon />
            </IconContainer>
            <Headline inViewport={moduleInViewport}>
              <Markdown>{headline || ''}</Markdown>
            </Headline>
            {subline && <Subline inViewport={moduleInViewport}>{subline}</Subline>}
            {button && button.externalUrl ? (
              <Button
                inViewport={moduleInViewport}
                rel="noreferrer"
                target="_blank"
                href={button.externalUrl}
                extraStyle={buttonStyle}
              >
                {button.text}
              </Button>
            ) : button.page ? (
              <Linky to={button}>
                <Button inViewport={moduleInViewport} extraStyle={buttonStyle}>
                  {button.text}
                </Button>
              </Linky>
            ) : null}
          </CTAColumn>
        </Row>
      </MaxWidth>
    </CTAContainer>
  )
}

export default CallToAction
