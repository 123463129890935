import styled from 'styled-components'
import { colors } from '@/styles/color'

export const ImageTextBlocksContainer = styled.div`
  position: relative;
  overflow: hidden;
  color: ${colors.moss.dark};
  margin-top: 70px;
  margin-bottom: 100px;
`
