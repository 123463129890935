import styled, { css } from 'styled-components'
import { colors } from '@/styles/color'
import { column } from '@/styles/grid'
import { mediaMax } from '@/styles/layout'

export const FootnotesContainer = styled.div`
  position: relative;
  color: ${colors.grey.base};
  font-size: 14px;
  line-height: 20px;
  margin: 75px 0 50px;
`

export const Column = styled.div`
  ${column(6, 2)};
  margin-bottom: 20px;
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )}
`

export const Line = styled.div`
  width: 30px;
  height: 1px;
  background-color: ${colors.grey.base};
  margin-bottom: 10px;
`
