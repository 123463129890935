import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { dynamicFontSize, H1 } from '@/styles/text'
import { extraSmall, small } from '@/styles/layout'

export const ProjectsContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`

export const Headline = styled(H1)`
  ${column(8, 1)};
  margin-block-end: 0.75em;
  ${props => dynamicFontSize('86px', props.children, 80)};
  ${small(
    css`
      ${props => dynamicFontSize('48px', props.children, 80)};
    `
  )}
  ${extraSmall(
    css`
      ${props => dynamicFontSize('32px', props.children, 80)};
    `
  )}
`
