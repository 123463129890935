import styled, { css } from 'styled-components'
import { neueMachinaFontStyle } from '@/styles/font'
import { colors } from '@/styles/color'
import { dynamicFontSize } from '@/styles/text'
import { extraLarge, extraSmall, large, mediaMin, medium, small } from '@/styles/layout'
import { column } from '@/styles/grid'
import { appear } from '@/styles/animation'

export const KeyDataContainer = styled.div``

const widthVWlarge = 80
const widthVW = 90
const largeColumns = 4
const mediumColumns = 3
const smallColumns = 2
const extraSmallColumns = 1

export const KeyDataColumn = styled.div`
  width: ${widthVW}vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: min(5vw, 50px);
  padding-bottom: min(10vw, 100px);
  ${large(css`
    width: ${widthVWlarge}vw;
  `)}
  ${mediaMin(
    'extraLarge',
    css`
      ${column(8, 1)};
    `
  )}
`

export const KeyValueContainer = styled.div`
  padding: 42px;
  width: ${widthVWlarge / largeColumns}vw;
  box-sizing: border-box;
  ${medium(css`
    width: ${widthVW / mediumColumns}vw;
  `)};
  ${small(css`
    width: ${widthVW / smallColumns}vw;
    padding: 25px;
  `)};
  ${extraSmall(css`
    width: ${widthVW / extraSmallColumns}vw;
  `)};
  ${extraLarge(css`
    width: 25%;
  `)};
  ${props => appear({ delay: props.index * 0.1 })}
`

export const Top = styled.div``

export const Bottom = styled.div``

const dynamicHeadlineMagicNumber = 0.5

export const Value = styled.span`
  ${neueMachinaFontStyle};
  font-size: 38px;
  ${props => dynamicFontSize('38px', props.children, (widthVWlarge / largeColumns) * dynamicHeadlineMagicNumber, true)};
  ${medium(css`
    ${props => dynamicFontSize('38px', props.children, (widthVW / mediumColumns) * dynamicHeadlineMagicNumber, true)};
  `)};
  ${small(css`
    ${props => dynamicFontSize('38px', props.children, (widthVW / smallColumns) * dynamicHeadlineMagicNumber, true)};
  `)};
  ${extraSmall(css`
    ${props =>
      dynamicFontSize('38px', props.children, (widthVW / extraSmallColumns) * dynamicHeadlineMagicNumber, true)};
  `)};
`

export const Key = styled.span`
  color: ${colors.grey.base};
`

export const Unit = styled.span`
  margin-left: 0.4em;
`
