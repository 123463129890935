import { useState, useMemo, useRef, useEffect } from 'react'
import {
  SliderContainer,
  AspectRatioContainer,
  Headline,
  Slide,
  imageStyle,
  PaginationContainer,
  PaginationBackground,
  paginationStyle,
  SlideInfoContainer,
  SlideTitle,
  BigImageSliderContainer,
  PlayButtonContainer,
  copyrightLabelStyle,
  Description,
} from './styles'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import Image from '@/components/common/Image'
import Slider from 'react-slick'
import Pagination from '@/components/common/Pagination'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'
import PlayButton from '@/components/common/PlayButton'
import { useMediaLayerStore } from '@/service/MediaLayerService'
import CopyrightLabel from '@/components/common/CopyrightLabel'

const BigMediaSlider = ({ module }) => {
  const { openMedia } = useMediaLayerStore()
  const moduleInViewport = useViewport()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [firstImageLoaded, setFirstImageLoaded] = useState(false)
  const sliderRef = useRef()
  const { title, slides } = module

  const showSliderContent = firstImageLoaded && moduleInViewport

  const paginationItems = useMemo(() => {
    return slides.map((slide, index) => {
      return { title: index + 1, active: index === activeSlideIndex, itemId: index }
    })
  }, [activeSlideIndex, slides])

  useEffect(() => {
    sliderRef.current.slickGoTo(activeSlideIndex)
  }, [activeSlideIndex])

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setActiveSlideIndex(next),
  }

  const handlePlayButtonClick = index => {
    openMedia([slides[index]], index)
  }

  return (
    <BigImageSliderContainer>
      <MaxWidth>
        {title && (
          <Row>
            <Headline inViewport={moduleInViewport}>{title}</Headline>
          </Row>
        )}
        {slides && slides.length > 0 && (
          <Row>
            <AspectRatioContainer inViewport={moduleInViewport}>
              <SliderContainer show={showSliderContent}>
                <Slider ref={sliderRef} {...settings}>
                  {slides.map((slide, index) => {
                    const { title, description, youtubeId, image } = slide
                    const showInfoBox = !!title || !!description || !!youtubeId
                    const loadHandler =
                      index === 0
                        ? () => {
                            setFirstImageLoaded(true)
                          }
                        : () => {}
                    return (
                      <Slide key={index}>
                        <Image
                          image={image}
                          desktopSizeFactor={0.8}
                          extraStyle={imageStyle}
                          loadHandler={loadHandler}
                        />
                        <CopyrightLabel image={image} extraStyle={copyrightLabelStyle} />
                        {showInfoBox && (
                          <SlideInfoContainer hasVideo={!!youtubeId}>
                            {title && <SlideTitle>{title}</SlideTitle>}
                            {description && <Description>{description}</Description>}
                            {youtubeId && (
                              <PlayButtonContainer>
                                <PlayButton clickHandler={() => handlePlayButtonClick(index)} />
                              </PlayButtonContainer>
                            )}
                          </SlideInfoContainer>
                        )}
                      </Slide>
                    )
                  })}
                </Slider>
              </SliderContainer>
              {slides.length > 1 && (
                <PaginationContainer>
                  <PaginationBackground />
                  <Pagination
                    horizontal
                    hideLabels
                    extraStyle={paginationStyle}
                    selectHandler={setActiveSlideIndex}
                    items={paginationItems}
                  />
                </PaginationContainer>
              )}
            </AspectRatioContainer>
          </Row>
        )}
      </MaxWidth>
    </BigImageSliderContainer>
  )
}

export default BigMediaSlider
