import Linky from '@/components/common/Linky'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import {
  LocationsContainer,
  LocationsColumn,
  IllustrationColumn,
  LocationButton,
  LocationName,
  LocationSubtitle,
  CenterCell,
  Table,
  LocationButtonContainer,
} from './styles'
import IlluCity from './assets/IlluCity.svg'
import IlluSmartphone from './assets/IlluSmartphone.svg'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const illustrations = {
  City: IlluCity,
  Smartphone: IlluSmartphone,
}

const Locations = ({ module }) => {
  const moduleInViewport = useViewport()
  const { illustration, locations } = module
  const renderIllustrationColumn = smallScreen => {
    const IlluComponent = illustrations[illustration]
    if (!IlluComponent) return null
    return (
      <IllustrationColumn smallScreen={smallScreen}>
        <IlluComponent />
      </IllustrationColumn>
    )
  }

  return (
    <LocationsContainer>
      <MaxWidth>
        <Row>
          {renderIllustrationColumn(true)}
          <LocationsColumn>
            <Table>
              <CenterCell>
                {locations &&
                  locations.map((location, index) => {
                    return (
                      <Linky to={location} key={location.id} Component={LocationButtonContainer}>
                        <LocationButton inViewport={moduleInViewport} index={index}>
                          <LocationName>{location.shortTitle ? location.shortTitle : location.title}</LocationName>
                          <LocationSubtitle>{location.teaserSubtitle}</LocationSubtitle>
                        </LocationButton>
                      </Linky>
                    )
                  })}
              </CenterCell>
            </Table>
          </LocationsColumn>
          {renderIllustrationColumn(false)}
        </Row>
      </MaxWidth>
    </LocationsContainer>
  )
}

export default Locations
