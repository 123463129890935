import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { colors } from '@/styles/color'
import { H2, H4 } from '@/styles/text'
import { neueMachinaFontStyle } from '@/styles/font'
import { mediaMax } from '@/styles/layout'
import { appear } from '@/styles/animation'

const paddingMobileVW = 5
const paddingVW = 2.5

export const JobsContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`

export const JobsColumn = styled.div`
  ${column(7, 2)};
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )}
`

export const JobTitle = styled(H4)`
  margin-left: ${paddingVW}vw;
  margin-right: ${paddingVW}vw;
  text-decoration: underline;
  ${neueMachinaFontStyle};
  line-height: 33px;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  color: ${colors.white};
  &:before,
  &:after {
    background-color: ${colors.white};
  }
  ${mediaMax(
    'medium',
    css`
      margin-left: ${paddingMobileVW}vw;
    `
  )}
`

export const JobsHeadline = styled(H2)`
  color: ${colors.moss.light};
  margin-block-end: 1.2em;
`

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaMax(
    'small',
    css`
      font-size: 12px;
    `
  )}
`

export const EmploymentType = styled.div`
  margin-left: ${paddingVW}vw;
  ${mediaMax(
    'medium',
    css`
      margin-left: ${paddingMobileVW}vw;
    `
  )}
`

export const JobLocation = styled.div`
  width: calc(${100 / 7}% - ${paddingVW}vw);
  text-align: right;
  margin-right: ${paddingVW}vw;
  ${props => appear({ startTransform: `translateX(100px)`, delay: 0.5 + props.index * 0.15 })};
  ${mediaMax(
    'medium',
    css`
      width: calc(${200 / 7}% - ${paddingMobileVW}vw);
      margin-right: ${paddingMobileVW}vw;
    `
  )}
`

export const JobContainer = styled.a`
  display: block;
  color: ${colors.white};
  text-decoration: none;
  padding: ${paddingVW}vw 0;
  border-top: 2px solid;
  background: linear-gradient(90deg, ${colors.blue.dark}ff 35.65%, ${colors.blue.dark}00 100%);
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, rgba(20, 75, 70, 1), rgba(20, 75, 70, 0));
  transition: color 0.2s;
  ${props => appear({ startTransform: `translateX(100px)`, delay: props.index * 0.15 })};
  @media (pointer: fine) {
    &:hover {
      ${JobTitle} {
        color: ${colors.moss.light};
      }
    }
  }
  &:last-of-type {
    border-bottom: 2px solid;
  }
  ${mediaMax(
    'medium',
    css`
      padding: ${paddingMobileVW}vw 0;
    `
  )}
`
