import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import { SectionIntroContainer, TextColumn, Copy, Headline } from './styles'
import Markdown from 'markdown-to-jsx'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const SectionIntro = ({ module }) => {
  const moduleInViewport = useViewport()
  const { headline, copy } = module
  return (
    <SectionIntroContainer>
      <MaxWidth>
        <Row>
          <TextColumn inViewport={moduleInViewport}>
            <Headline>
              <Markdown>{headline || ''}</Markdown>
            </Headline>
            <Copy>{copy}</Copy>
          </TextColumn>
        </Row>
      </MaxWidth>
    </SectionIntroContainer>
  )
}

export default SectionIntro
