import { Group } from './styles'
import BackgroundGrid from '@/components/common/BackgroundGrid'

const ModuleGroupBackground = ({ children, light = false, first = false, length }) => {
  return (
    <Group light={light}>
      {!first && <BackgroundGrid dark={!light} inner length={length} />}
      {children}
    </Group>
  )
}

export default ModuleGroupBackground
