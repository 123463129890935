import styled, { css } from 'styled-components'
import { textInputStyle } from '@/components/common/Input'
import { scrollbarStyle } from '@/styles/scroll'
import easing from '@/styles/easing'

export const StyledTextArea = styled.textarea`
  ${textInputStyle};
  resize: none;
  ${scrollbarStyle};
  transition: min-height 0.5s ${easing.inOutStrong};
  display: block;
  box-sizing: border-box;
  width: 100%;
  &:focus {
    min-height: 150px;
  }
  ${props =>
    props.hasContent
      ? css`
          min-height: 150px;
        `
      : css`
          &:not(:focus) {
            min-height: 52px;
          }
        `}
  ${props => props.extraStyle};
`

const TextArea = props => {
  return <StyledTextArea {...props.register} {...props} />
}

export default TextArea
