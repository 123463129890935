import React, { useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTransitionStore } from '@/service/TransitionService'

const ViewportContext = React.createContext(false)
export const useViewport = () => useContext(ViewportContext)

const ViewportDetector = ({ children }) => {
  const { ref, inView } = useInView()
  const transitionOngoing = useTransitionStore(state => state.transitionOngoing)
  const [wasInView, setWasInView] = useState(false)
  useEffect(() => {
    if (inView && !transitionOngoing) setWasInView(true)
  }, [inView, transitionOngoing])
  return (
    <div ref={ref}>
      <ViewportContext.Provider value={wasInView}>{children}</ViewportContext.Provider>
    </div>
  )
}

export default ViewportDetector
