import Linky from '@/components/common/Linky'
import { ImageContainer, imageStyle, ProjectSubtitle, ProjectTeaserContainer, ProjectTitle, Teaser } from './styles'
import Image from '@/components/common/Image'
import CopyrightLabel from '@/components/common/CopyrightLabel'

const ProjectTeaser = ({ project, moduleInViewport, index }) => {
  return (
    <Linky to={project} key={project.id} Component={ProjectTeaserContainer}>
      <Teaser inViewport={moduleInViewport} index={index}>
        <ImageContainer>
          <Image extraStyle={imageStyle} image={project.teaserImage} desktopSizeFactor={0.3} />
          <CopyrightLabel image={project.teaserImage} />
        </ImageContainer>
        <ProjectTitle>{project.shortTitle ? project.shortTitle : project.title}</ProjectTitle>
        <ProjectSubtitle>{project.teaserSubtitle}</ProjectSubtitle>
      </Teaser>
    </Linky>
  )
}

export default ProjectTeaser
