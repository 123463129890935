import styled, { css } from 'styled-components'
import { column, offsetRight } from '@/styles/grid'
import { dynamicFontSize, dynamicFontSizeByMaxLetters, H2, outlineStyle } from '@/styles/text'
import { colors } from '@/styles/color'
import { neueMachinaFontStyle } from '@/styles/font'
import easing from '@/styles/easing'
import { Row } from '@/components/common/Grid/Row'
import { mediaMax, mediaMin } from '@/styles/layout'

export const ProjectNavigationContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 8vw;
`

export const ProjectRow = styled(Row)`
  flex-direction: row-reverse;
`

export const ProjectTitle = styled(H2)`
  ${neueMachinaFontStyle};
  ${outlineStyle(colors.moss.base)};
  position: relative;
  transition: transform 0.15s ${easing.outStrong}, color 0.2s, text-stroke-color 0.2s;
  max-width: 100%;
  margin: 0;
  ${props => dynamicFontSizeByMaxLetters('68px', props.maxLetters, 37.5)};
  ${mediaMax(
    'small',
    css`
      ${props => dynamicFontSizeByMaxLetters('68px', props.maxLetters, 80)};
    `
  )}
`

export const ProjectContainer = styled.a`
  text-decoration: none;
  display: inline-block;
  position: relative;
  @media (pointer: fine) {
    &:hover {
      ${ProjectTitle} {
        color: ${colors.moss.light};
        -webkit-text-stroke: 1px transparent;
      }
    }
  }
`

export const ArrowContainer = styled.div`
  position: absolute;
  bottom: -1.2em;
  svg {
    display: block;
  }
`

const columnStyle = css`
  ${column(4, 0)};
  position: relative;
  ${mediaMax(
    'small',
    css`
      ${column(10, 0)};
      padding: 10px 0 70px;
    `
  )}
`

export const LeftColumn = styled.div`
  ${columnStyle};
  ${mediaMin(
    'medium',
    css`
      ${offsetRight(2)};
    `
  )}
  ${ProjectTitle} {
    transform: translateX(-15px);
  }
  ${ProjectContainer} {
    @media (pointer: fine) {
      &:hover {
        ${ProjectTitle} {
          transform: translateX(15px);
        }
      }
    }
  }
  ${ArrowContainer} {
    right: 15px;
  }
`

export const RightColumn = styled.div`
  ${columnStyle};
  text-align: right;
  ${ProjectTitle} {
    transform: translateX(15px);
  }
  ${ProjectContainer} {
    @media (pointer: fine) {
      &:hover {
        ${ProjectTitle} {
          transform: translateX(-15px);
        }
      }
    }
  }
  ${ArrowContainer} {
    transform: rotate(180deg);
    left: 15px;
  }
  ${mediaMax(
    'small',
    css`
      ${column(10, 0)};
    `
  )}
`
