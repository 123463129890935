import styled, { css } from 'styled-components'
import { column, row } from '@/styles/grid'
import {dynamicFontSize, H2, pseudoH2} from '@/styles/text'
import { mediaMax } from '@/styles/layout'
import { colors } from '@/styles/color'
import { appear } from '@/styles/animation'

export const CTAContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 140px;
`

export const CTAColumn = styled.div`
  ${column(4, 3)};
  text-align: center;

  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )}
`

export const Subline = styled.p`
  ${appear({ delay: 0.25 })};
`

export const Headline = styled(pseudoH2)`
  ${appear({})};
  ${props => dynamicFontSize('38px', props.children, 40)};
  strong {
    color: ${colors.moss.light};
  }
  ${mediaMax(
    'small',
    css`
      ${props => dynamicFontSize('38px', props.children, 80)};
    `
  )}
`

export const IconContainer = styled.div`
  ${appear({ startTransform: `translate3d(-100px, 100px, 0)`, delay: 0.5 })};
`

export const buttonStyle = css`
  margin-top: 65px;
  ${appear({ delay: 0.5 })};
`
