import { useInView } from 'react-intersection-observer'
import {
  Headline,
  ImageContainer,
  imageStyle,
  Row,
  TextColumn,
  TextContainer,
  TextOuterContainer,
  CopyContainer,
} from './styles'
import Image from '@/components/common/Image'
import Markdown from 'markdown-to-jsx'
import Linky from '@/components/common/Linky'
import UnderlinedTextLink from '@/components/common/UnderlinedTextLink'
import { useEffect, useState } from 'react'
import CopyrightLabel from '@/components/common/CopyrightLabel'

const Block = ({ block, index, handleImageClick }) => {
  const { ref, inView } = useInView()
  const [wasInView, setWasInView] = useState(false)
  useEffect(() => {
    if (inView) setWasInView(true)
  }, [inView])
  const oddRow = index % 2 !== 0
  const ic = isLeft => (
    <ImageContainer
      odd={oddRow}
      isLeft={isLeft}
      onClick={() => {
        handleImageClick(index)
      }}
    >
      <Image extraStyle={imageStyle} image={block.image} desktopSizeFactor={0.3} mobileSizeFactor={0.85} />
      <CopyrightLabel image={block.image} />
    </ImageContainer>
  )
  return (
    <Row inViewport={wasInView} ref={ref}>
      {ic(true)}
      <TextColumn>
        <TextOuterContainer>
          <TextContainer first={index === 0}>
            <Headline>
              <Markdown>{block.headline || ''}</Markdown>
            </Headline>
            {block.copy && (
              <CopyContainer>
                <Markdown>{block.copy || ''}</Markdown>
              </CopyContainer>
            )}
            {block.link && (
              <Linky to={block.link}>
                <UnderlinedTextLink>{block.link.text}</UnderlinedTextLink>
              </Linky>
            )}
          </TextContainer>
        </TextOuterContainer>
      </TextColumn>
      {ic(false)}
    </Row>
  )
}

export default Block
