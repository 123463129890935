import { ButtonsModuleContainer, ButtonColumn, ButtonRow, buttonExtraStyle } from './styles'
import MaxWidth from '@/components/common/MaxWidth'
import Button from '@/components/common/Button'
import Linky from '@/components/common/Linky'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const Buttons = ({ module }) => {
  const moduleInViewport = useViewport()
  const { buttons } = module
  if (!buttons) return null
  return (
    <ButtonsModuleContainer>
      <MaxWidth>
        {buttons.map((button, index) => {
          const secondary = index !== 0
          return (
            <ButtonRow key={index}>
              <ButtonColumn>
                <Linky to={button}>
                  <Button outline={secondary} extraStyle={buttonExtraStyle}>
                    {button.text}
                  </Button>
                </Linky>
              </ButtonColumn>
            </ButtonRow>
          )
        })}
      </MaxWidth>
    </ButtonsModuleContainer>
  )
}

export default Buttons
