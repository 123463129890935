import MaxWidth from '@/components/common/MaxWidth'
import { Headline, ProjectsContainer } from './styles'
import { Row } from '@/components/common/Grid/Row'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'
import ProjectTeaser from './ProjectTeaser'

const Projects = ({ module }) => {
  const moduleInViewport = useViewport()
  const { headline, projects } = module

  return (
    <ProjectsContainer>
      <MaxWidth>
        <Row>{headline && <Headline>{headline}</Headline>}</Row>
        <Row>
          {projects &&
            projects.map((project, index) => (
              <ProjectTeaser key={index} index={index} project={project} moduleInViewport={moduleInViewport} />
            ))}
        </Row>
      </MaxWidth>
    </ProjectsContainer>
  )
}

export default Projects
