import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { colors } from '@/styles/color'
import { H2 } from '@/styles/text'
import { mediaMax } from '@/styles/layout'
import { appear } from '@/styles/animation'

export const SectionIntroContainer = styled.div`
  position: relative;
  overflow: hidden;
  color: ${colors.blue.dark};
  margin-top: 60px;
  margin-bottom: 130px;
`

export const Headline = styled(H2)`
  margin-block-end: 0.25em;
`

export const Copy = styled.p`
  font-size: 24px;
  line-height: 30px;
  font-weight: 200;
`

export const TextColumn = styled.div`
  ${column(5, 2.5)};
  text-align: center;
  ${mediaMax(
    'medium',
    css`
      ${column(6, 2)};
    `
  )}
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )}

  ${Headline} {
    ${appear({ duration: 0.75 })};
  }
  ${Copy} {
    ${appear({ duration: 0.5, delay: 0.25 })};
  }
`
