import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { H4 } from '@/styles/text'
import { colors } from '@/styles/color'
import { underlinedTextLinkHoverStyle, underlinedTextLinkStyle } from '@/components/common/UnderlinedTextLink'
import { mediaMin, mediaMax, small } from '@/styles/layout'
import { appear } from '@/styles/animation'

export const LocationsContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
`

export const LocationsColumn = styled.div`
  ${column(2, 2)};
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
      margin-top: 30px;
    `
  )}
`

export const IllustrationColumn = styled.div`
  ${column(5, 0)};
  img {
    width: 100%;
  }
  ${props =>
    props.smallScreen
      ? css`
          ${column(8, 1)};
          ${mediaMin(
            'medium',
            css`
              visibility: hidden;
              height: 0;
              overflow: hidden;
              //display: none;
            `
          )}
        `
      : css`
          ${mediaMax(
            'small',
            css`
              display: none;
            `
          )}
        `}
`

export const LocationName = styled(H4)`
  ${underlinedTextLinkStyle};
  color: ${colors.white};
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  &:before,
  &:after {
    background-color: ${colors.white};
  }
`

export const Table = styled.div`
  display: table;
  height: 100%;
`

export const CenterCell = styled.div`
  display: table-cell;
  vertical-align: middle;
`

export const LocationSubtitle = styled.span`
  display: block;
`

export const LocationButtonContainer = styled.div`
  padding: 2em 0;
  ${small(css`
    /* width: 50%; */
    float: left;
  `)}
`

export const LocationButton = styled.a`
  display: block;
  color: ${colors.white};
  text-decoration: none;
  ${props => appear({ startTransform: `translateX(-100px)`, delay: props.index * 0.1 })};
  @media (pointer: fine) {
    &:hover {
      ${LocationName} {
        ${underlinedTextLinkHoverStyle};
      }
    }
  }
`
