import styled, { css } from 'styled-components'
import { center, mediaMax } from '@/styles/layout'
import { dynamicFontSize, H2 } from '@/styles/text'
import { column, row } from '@/styles/grid'
import { appear } from '@/styles/animation'

export const ImageContainer = styled.div`
  position: relative;
  max-height: 540px;
  cursor: zoom-in;
  padding-top: ${props => (props.odd ? 45 : 40)}%;
  overflow: hidden;
  display: ${props => ((props.odd && props.isLeft) || (!props.odd && !props.isLeft) ? 'block' : 'none')};
  ${mediaMax(
    'small',
    css`
      display: ${props => (!props.isLeft ? 'block' : 'none')};
    `
  )};
`

export const imageStyle = css`
  object-fit: cover;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  position: absolute;
  ${center}
`

export const TextColumn = styled.div`
  position: relative;
`

export const TextOuterContainer = styled.div`
  display: table;
  height: 100%;
  ${mediaMax(
    'small',
    css`
      margin-bottom: 15%;
    `
  )};
`

export const TextContainer = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;

  ${mediaMax(
    'small',
    css`
      ${props =>
        !props.first &&
        css`
          padding-top: 10%;
        `};
      margin-bottom: 30px;
    `
  )};
`

export const Headline = styled(H2)`
  margin-top: 0;
  font-size: 38px;
  
  ${mediaMax(
    'medium',
    css`
      font-size: 24px;
    `
  )}
  ${mediaMax(
    'small',
    css`
      ${props => dynamicFontSize('38px', props.children, 90)}
    `
  )}
`

export const Row = styled.div`
  ${row};
  position: relative;
  margin-bottom: 135px;
  &:nth-of-type(odd) {
    ${ImageContainer} {
      ${column(3, 1)}
      ${appear({ startTransform: `translateX(10%)`, duration: 0.75, alsoDisappear: true })}
    }
    ${TextColumn} {
      ${column(4, 1)};
      ${appear({ startTransform: `translateY(50px)`, duration: 0.5, alsoDisappear: true })}
    }
  }
  &:nth-of-type(even) {
    ${ImageContainer} {
      ${column(4, 0)}
      ${appear({ startTransform: `translateX(-10%)`, duration: 0.75, alsoDisappear: true })}
    }
    ${TextColumn} {
      ${column(3, 1)};
      ${appear({ startTransform: `translateY(50px)`, duration: 0.5, alsoDisappear: true })}
    }
  }
  ${mediaMax(
    'small',
    css`
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 80px;
      }
      &:nth-of-type(even),
      &:nth-of-type(odd) {
        ${ImageContainer} {
          ${column(8, 1)};
          padding-top: 100%;
        }
        ${TextColumn} {
          ${column(8, 1)};
        }
      }
    `
  )}
`

export const CopyContainer = styled.div`
  padding-bottom: 1.2em;
  p:last-of-type {
    margin-bottom: 0;
  }
  strong {
    font-weight: 500;
  }
`
