import { ImageContainer, imageStyle } from './styles'
import Image from '@/components/common/Image'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'
import CopyrightLabel from '@/components/common/CopyrightLabel'

const ImageBlock = ({ clickHandler, column, cImage, image, desktopSizeFactor }) => {
  const { ref, inView } = useInView()
  const [wasInView, setWasInView] = useState(false)
  useEffect(() => {
    if (inView) setWasInView(true)
  }, [inView])
  return (
    <ImageContainer onClick={clickHandler} {...column} {...cImage} ref={ref} inViewport={wasInView}>
      <Image desktopSizeFactor={desktopSizeFactor} image={image} extraStyle={imageStyle} />
      <CopyrightLabel image={image} />
    </ImageContainer>
  )
}

export default ImageBlock
