import { FootnotesContainer, Column, Line } from './styles'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'

const Footnotes = ({ module }) => {
  const { footnotes } = module
  return (
    <FootnotesContainer>
      <MaxWidth>
        {footnotes.map((footnote, index) => (
          <Row key={index}>
            <Column>
              {index === 0 && <Line />}
              {footnote.text}
            </Column>
          </Row>
        ))}
      </MaxWidth>
    </FootnotesContainer>
  )
}

export default Footnotes
