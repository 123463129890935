import {
  ContactContainer,
  HeadlineColumn,
  FormColumn,
  SuccessColumn,
  Headline,
  inputStyle,
  ErrorMessage,
  Terms,
  checkboxStyle,
  SuccessIconContainer,
  SuccessHeadline,
  FormFootnote,
} from './styles'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CMS_URLS, RUNTIME_ENVIRONMENT_NAME } from '@/utils/cms'
import { Input } from '@/components/common/Input'
import { NonLinkButton } from '@/components/common/Button'
import { Checkbox } from '@/components/common/Checkbox'
import CheckboxIcon from './assets/checkbox.svg'
import TextArea from '@/components/common/TextArea'
import Markdown from 'markdown-to-jsx'

const Contact = ({ module }) => {
  const moduleInViewport = useViewport()
  const successRef = useRef()
  const { register, handleSubmit, formState, getValues, setError, clearErrors, watch } = useForm()
  const { errors, isSubmitting, isSubmitted, isSubmitSuccessful } = formState
  const [serverError, setServerError] = useState(null)
  const success = !serverError && isSubmitted && isSubmitSuccessful
  const textAreaHasContent = !!watch('topic')
  const onSubmit = async message => {
    if (!getValues('agreed')) {
      setError('agreed', {
        type: 'custom',
        message: 'NOT_AGREED',
      })
      return
    }
    try {
      const cmsUrl = CMS_URLS[RUNTIME_ENVIRONMENT_NAME]
      await axios.post(`${cmsUrl}/contact/request`, message)
      setServerError(null)
      clearErrors()
    } catch (error) {
      const serverError = error.response?.data?.message || 'OTHER_ERROR'
      setServerError(serverError)
    }
  }

  useEffect(() => {
    if (success) successRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [success])

  const renderInput = (name, placeholder, appearIndex, required = false, errorMessage, type = 'text') => {
    return (
      <>
        <Input
          extraStyle={inputStyle}
          appearIndex={appearIndex}
          type={type}
          placeholder={placeholder}
          {...register(name, { required })}
        />
        {errors[name] && !!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    )
  }
  const formTexts = Object.keys(module.data).reduce((acc, key) => {
    const value = module.data[key]
    if (value) {
      acc[key] = value
    }
    return acc
  }, {})
  const {
    formErrorEmail,
    formErrorName,
    formErrorServer,
    formErrorTerms,
    formHeadline,
    formPlaceholderEmail,
    formPlaceholderName,
    formPlaceholderPhone,
    formPlaceholderTopic,
    formRequired,
    formSubmitButton,
    formSuccessHeadline,
    formTerms,
  } = formTexts

  return (
    <ContactContainer>
      <MaxWidth>
        {!success && (
          <Row>
            <HeadlineColumn inViewport={moduleInViewport}>
              <Headline>
                <Markdown>{formHeadline || ''}</Markdown>
              </Headline>
            </HeadlineColumn>
            <FormColumn inViewport={moduleInViewport}>
              <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
                {renderInput('name', formPlaceholderName, 0, true, formErrorName)}
                {renderInput('email', formPlaceholderEmail, 1, true, formErrorEmail, 'email')}
                {renderInput('phone', formPlaceholderPhone, 2)}
                <TextArea
                  placeholder={formPlaceholderTopic}
                  hasContent={textAreaHasContent}
                  rows={1}
                  register={register('topic', { required: false })}
                />
                <Terms>
                  <Checkbox
                    extraStyle={checkboxStyle}
                    id="agreed"
                    type="checkbox"
                    register={register('agreed', {
                      validate: () => {
                        return getValues('agreed')
                      },
                    })}
                  />
                  <label htmlFor="agreed">
                    {formTerms}
                    {errors.agreed && <ErrorMessage style={{ paddingLeft: 0 }}>{formErrorTerms}</ErrorMessage>}
                  </label>
                </Terms>
                <NonLinkButton type="submit" disabled={isSubmitting}>
                  {formSubmitButton}
                </NonLinkButton>
                <FormFootnote>{formRequired}</FormFootnote>
                {serverError && !!formErrorServer && <ErrorMessage>{formErrorServer}</ErrorMessage>}
              </form>
            </FormColumn>
          </Row>
        )}
        {success && (
          <Row>
            <SuccessColumn ref={successRef}>
              <SuccessIconContainer>
                <CheckboxIcon />
              </SuccessIconContainer>
              <SuccessHeadline>
                <strong>{formSuccessHeadline}</strong>
              </SuccessHeadline>
            </SuccessColumn>
          </Row>
        )}
      </MaxWidth>
    </ContactContainer>
  )
}

export default Contact
