import { CopyrightLabelContainer, LineContainer, Line } from './styles'
import { css } from 'styled-components'

const CopyrightLabel = ({ image, extraStyle = css`` }) => {
  if (!image) return null
  const { caption } = image
  if (!caption) return null
  return (
    <CopyrightLabelContainer extraStyle={extraStyle}>
      <LineContainer>
        <Line />
      </LineContainer>
      © {caption}
    </CopyrightLabelContainer>
  )
}

export default CopyrightLabel
