import MaxWidth from '@/components/common/MaxWidth'
import { ImageTextBlocksContainer } from './styles'
import Block from '@/components/Pages/Page/Modules/ImageTextBlocks/Block'
import { useMediaLayerStore } from '@/service/MediaLayerService'

const ImageTextBlocks = ({ module }) => {
  const { imageTextBlock } = module
  const handleImageClick = index => {
    useMediaLayerStore.getState().openMedia([imageTextBlock[index]], index)
  }
  return (
    <ImageTextBlocksContainer>
      <MaxWidth>
        {imageTextBlock.map((block, index) => (
          <Block key={index} block={block} index={index} handleImageClick={handleImageClick} />
        ))}
      </MaxWidth>
    </ImageTextBlocksContainer>
  )
}

export default ImageTextBlocks
