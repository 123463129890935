import styled, { css } from 'styled-components'
import { column, row } from '@/styles/grid'
import { extraSmall, medium, small } from '@/styles/layout'

export const ButtonsModuleContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin: 3vw 0 8vw;
`

export const ButtonRow = styled.div`
  ${row};
  text-align: center;
  margin-bottom: 15px;
`

export const ButtonColumn = styled.div`
  ${column(2, 4)};
  ${medium(css`
    ${column(3, 3.5)};
  `)};
  ${small(css`
    ${column(6, 2)};
  `)};
  ${extraSmall(css`
    ${column(8, 1)};
  `)};
`

export const buttonExtraStyle = css`
  min-width: 100%;
`
