import styled, { css } from 'styled-components'
import { extraSmall, mediaMax, small } from '@/styles/layout'

export const PlayButtonContainer = styled.div`
  min-height: 45px;
  padding: 0 10px 0 60px;
  position: relative;
  cursor: pointer;
  ${mediaMax('small', css``)}
`

export const IconContainer = styled.div`
  position: absolute;
  left: 0;
`

export const LabelContainer = styled.div`
  font-size: 16px;
  line-height: 1.2em;
  position: absolute;
  top: calc(50% - 0.6em);
  ${small(css`
    font-size: 12px;
  `)} ${extraSmall(css`
    font-size: 12px;
  `)};
`
