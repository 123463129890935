import { fullSizeAndAbsolute } from '@/styles/layout'
import styled from 'styled-components'
import { colors } from '@/styles/color'

const StyledCheckbox = styled.input`
  appearance: none;
  position: relative;
  background-color: transparent;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.white};
  box-sizing: border-box;
  border-radius: 1px;
  cursor: pointer;
  &:checked {
    &:after {
      content: '';
      display: block;
      background: url(https://des-gmbh-assets.s3.amazonaws.com/website/checkmark.svg);
      background-repeat: no-repeat;
      ${fullSizeAndAbsolute};
      transform: translate3D(2px, 1px, 0);
    }
  }
  ${props => props.extraStyle};
`

export const Checkbox = props => {
  return (
    <div>
      <StyledCheckbox {...props.register} {...props} />
    </div>
  )
}
