import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { dynamicFontSize, H1, pseudoH3 } from '@/styles/text'
import { colors } from '@/styles/color'
import { extraSmall, mediaMax, small } from '@/styles/layout'
import { appear } from '@/styles/animation'

export const IntroContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 8vw;
`

export const UpperContainer = styled.div`
  ${column(8, 1)};
`

export const LowerContainer = styled.div`
  ${column(6, 2)};
  ${mediaMax(
    'small',
    css`
      ${column(7, 2)};
    `
  )};
`

export const Headline = styled(H1)`
  margin-block-end: 0.2em;
  ${appear({})};
  ${props => dynamicFontSize('86px', props.children, 80)};
  ${small(
    css`
      ${props => dynamicFontSize('48px', props.children, 80)};
    `
  )}
  ${extraSmall(
    css`
      ${props => dynamicFontSize('32px', props.children, 80)};
    `
  )}
`

export const Subline = styled(pseudoH3)`
  margin-block-start: 0.2em;
  margin-block-end: 0.83em;
  ${appear({})};
  strong {
    color: ${colors.moss.light};
  }
`

export const Paragraph = styled.p`
  margin-top: 0;
  color: ${colors.grey.light};
  font-size: 24px;
  line-height: 1.25em;
  font-weight: 300;
  ${appear({ delay: 0.25 })};
  ${mediaMax(
    'medium',
    css`
      font-size: 20px;
    `
  )}
`
