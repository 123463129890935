import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { dynamicFontSize, H2 } from '@/styles/text'
import { colors } from '@/styles/color'
import { mediaMax, medium } from '@/styles/layout'
import { appear } from '@/styles/animation'
import { trasandinaFontStyle } from '@/styles/font'

export const ContactContainer = styled.div`
  margin-top: max(50px, 8vw);
  margin-bottom: max(100px, 12vw);
`

export const HeadlineColumn = styled.div`
  ${column(2, 2)};
  ${medium(css`
    ${column(3, 1)};
  `)}
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )};
  ${appear({})};
`

export const FormColumn = styled.div`
  ${column(3, 1)};
  ${medium(css`
    ${column(4, 1)};
  `)}
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )};
  ${appear({ startTransform: ``, delay: 0.5 })};
`

export const SuccessColumn = styled.div`
  ${column(6, 2)};
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)};
    `
  )};
`

export const SuccessIconContainer = styled.div`
  text-align: center;
  svg {
    margin-left: 7px;
  }
`

export const SuccessHeadline = styled(H2)`
  text-align: center;
  strong {
    color: ${colors.moss.light};
  }
  ${props => dynamicFontSize('38px', props.children, 60)};
  ${mediaMax(
    'small',
    css`
      ${props => dynamicFontSize('38px', props.children, 80)};
    `
  )};
  margin-bottom: 2em;
`

export const Headline = styled(H2)`
  margin-block-start: 0;
  strong {
    color: ${colors.moss.light};
  }
  ${props => dynamicFontSize('38px', props.children, 19)};
  ${medium(css`
    ${props => dynamicFontSize('38px', props.children, 29)};
  `)}
  ${mediaMax(
    'small',
    css`
      ${props => dynamicFontSize('38px', props.children, 80)};
    `
  )};
`

export const ErrorMessage = styled.div`
  color: #f56463;
  font-size: 11px;
  line-height: 12px;
  padding: 8px;
`

export const inputStyle = css`
  display: block;
  width: 100%;
  box-sizing: border-box;
`

export const Terms = styled.div`
  margin: 30px 5px;
  display: flex;
  label {
    margin-left: 16px;
  }
`

export const checkboxStyle = css`
  margin-top: 8px;
`

export const FormFootnote = styled.div`
  margin-top: 12px;
  ${trasandinaFontStyle};
  color: ${colors.grey.light};
  font-size: 14px;
  line-height: 1.25em;
  font-weight: 300;
`
