import Linky from '@/components/common/Linky'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import { LongTextContainer, LongTextsContainer, LinkContainer, Headline2, Headline3 } from './styles'
import Markdown from 'markdown-to-jsx'
import UnderlinedTextLink from '@/components/common/UnderlinedTextLink'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const LongText = ({ module }) => {
  const moduleInViewport = useViewport()
  const { link, copy } = module
  return (
    <LongTextContainer>
      <MaxWidth>
        <Row>
          <LongTextsContainer>
            {copy && (
              <Markdown
                options={{
                  forceBlock: true,
                  overrides: {
                    h2: Headline2,
                    h3: Headline3,
                  },
                }}
              >
                {copy || ''}
              </Markdown>
            )}
            {link && (
              <LinkContainer>
                <Linky to={link}>
                  <UnderlinedTextLink>{link.text}</UnderlinedTextLink>
                </Linky>
              </LinkContainer>
            )}
          </LongTextsContainer>
        </Row>
      </MaxWidth>
    </LongTextContainer>
  )
}

export default LongText
