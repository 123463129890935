import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import { KeyDataContainer, KeyDataColumn, KeyValueContainer, Top, Bottom, Value, Key, Unit } from './styles'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const KeyData = ({ module }) => {
  const moduleInViewport = useViewport()
  const { keyValues } = module
  return (
    <KeyDataContainer>
      <MaxWidth>
        <Row>
          <KeyDataColumn>
            {keyValues.map((keyValue, index) => (
              <KeyValueContainer key={index} index={index} inViewport={moduleInViewport}>
                <Top>
                  <Value>{keyValue.value}</Value>
                  <Unit>{keyValue.unit}</Unit>
                </Top>
                <Bottom>
                  <Key>{keyValue.key}</Key>
                </Bottom>
              </KeyValueContainer>
            ))}
          </KeyDataColumn>
        </Row>
      </MaxWidth>
    </KeyDataContainer>
  )
}

export default KeyData
