import { PlayButtonContainer, IconContainer, LabelContainer } from './styles'
import PlayIcon from './assets/play.svg'

const PlayButton = ({ clickHandler }) => {
  return (
    <PlayButtonContainer onClick={clickHandler}>
      <IconContainer>
        <PlayIcon />
      </IconContainer>
      <LabelContainer>
        <div>Video abspielen</div>
      </LabelContainer>
    </PlayButtonContainer>
  )
}

export default PlayButton
