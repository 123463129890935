import MaxWidth from '@/components/common/MaxWidth'
import {
  ProjectNavigationContainer,
  LeftColumn,
  RightColumn,
  ProjectTitle,
  ProjectContainer,
  ProjectRow,
  ArrowContainer,
} from './styles'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'
import Linky from '@/components/common/Linky'
import { useMemo } from 'react'
import Arrow from './assets/arrow.svg'

const getTitle = page => (page.shortTitle ? page.shortTitle : page.title)

const ProjectNavigation = ({ module }) => {
  const moduleInViewport = useViewport()
  const { previousPage, nextPage } = module

  if (!previousPage || !nextPage) return null

  const maxLetters = useMemo(() => {
    let titleWords = []
    titleWords = titleWords.concat(getTitle(previousPage).split(' '))
    titleWords = titleWords.concat(getTitle(nextPage).split(' '))
    return titleWords.sort((a, b) => b.length - a.length)[0].length
  }, [previousPage, nextPage])

  const renderProjectLink = page => (
    <Linky to={page}>
      <ProjectContainer>
        <ProjectTitle maxLetters={maxLetters}>{getTitle(page)}</ProjectTitle>
        <ArrowContainer>
          <Arrow/>
        </ArrowContainer>
      </ProjectContainer>
    </Linky>
  )

  return (
    <ProjectNavigationContainer>
      <MaxWidth>
        <ProjectRow>
          <RightColumn>{renderProjectLink(nextPage)}</RightColumn>
          <LeftColumn>{renderProjectLink(previousPage)}</LeftColumn>
        </ProjectRow>
      </MaxWidth>
    </ProjectNavigationContainer>
  )
}

export default ProjectNavigation
