import { getPageData } from '@/utils/cms'
import BackgroundGrid from '@/components/common/BackgroundGrid'
import { PageContentContainer } from './styles'
import ImageTextBlocks from './Modules/ImageTextBlocks'
import CallToAction from './Modules/CallToAction'
import Jobs from './Modules/Jobs'
import Locations from './Modules/Locations'
import Projects from './Modules/Projects'
import Intro from './Modules/Intro'
import LongText from './Modules/LongText'
import BigMediaSlider from './Modules/BigMediaSlider'
import Contact from './Modules/Contact'
import Buttons from './Modules/Buttons'
import SectionIntro from './Modules/SectionIntro'
import KeyData from './Modules/KeyData'
import ImageGallery from './Modules/ImageGallery'
import ProjectNavigation from './Modules/ProjectNavigation'
import Footnotes from './Modules/Footnotes'
import ModuleGroupBackground from './ModuleGroupBackground'
import ViewportDetector from './ViewportDetector'

const pageModuleComponents = {
  'page-modules.call-to-action': { component: CallToAction, light: false },
  'page-modules.intro': { component: Intro, light: false },
  'page-modules.jobs': { component: Jobs, light: false },
  'page-modules.locations': { component: Locations, light: false },
  'page-modules.projects': { component: Projects, light: false },
  'page-modules.project-navigation': { component: ProjectNavigation, light: false },
  'page-modules.big-media-slider': { component: BigMediaSlider, light: false },
  'page-modules.contact': { component: Contact, light: false },
  'page-modules.key-data': { component: KeyData, light: false },
  'page-modules.image-gallery': { component: ImageGallery, light: false },
  'page-modules.long-text': { component: LongText, light: true },
  'page-modules.buttons': { component: Buttons, light: true },
  'page-modules.section-intro': { component: SectionIntro, light: true },
  'page-modules.image-text-blocks': { component: ImageTextBlocks, light: true },
  'page-modules.footnotes': { component: Footnotes, light: true },
}

const splitModulesByBackground = modules => {
  const splitModules = []
  modules.forEach(module => {
    const component = pageModuleComponents[module['__component']]
    if (splitModules.length > 0 && splitModules[splitModules.length - 1].light === component.light) {
      splitModules[splitModules.length - 1].modules.push(module)
    } else {
      splitModules.push({ light: component.light, modules: [module] })
    }
  })
  return splitModules
}

const Page = ({ pageData }) => {
  const { page } = pageData
  if (!page) return null
  const { modules } = page
  const splitModules = splitModulesByBackground(modules)
  return (
    <>
      <BackgroundGrid length={splitModules[0] && splitModules[0].modules.length} />
      <PageContentContainer>
        {splitModules &&
          splitModules.map((moduleGroup, groupIndex) => {
            const { modules, light } = moduleGroup
            return (
              <ModuleGroupBackground key={groupIndex} light={light} first={groupIndex === 0} length={modules.length}>
                {modules &&
                  modules.map((module, moduleIndex) => {
                    const Component = pageModuleComponents[module['__component']].component
                    return (
                      Component && (
                        <ViewportDetector key={moduleIndex + module['__component']}>
                          <Component module={module} />
                        </ViewportDetector>
                      )
                    )
                  })}
              </ModuleGroupBackground>
            )
          })}
      </PageContentContainer>
    </>
  )
}

export async function getStaticProps(context) {
  try {
    const pageData = await getPageData(context)
    return {
      notFound: !pageData.page.id,
      props: { pageData },
      revalidate: 2,
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log('error', e.config.url)
    return {
      props: { error: e.message },
      revalidate: 2,
    }
  }
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

Page.uniqueId = 'Page'
export default Page
