import styled, { css } from 'styled-components'
import easing from '@/styles/easing'
import { colors } from '@/styles/color'
import { neueMachinaFontStyle } from '@/styles/font'

export const underlinedTextLinkStyle = css`
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: ${props => (props.color ? props.color : colors.moss.base)};
  ${neueMachinaFontStyle};
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: ${props => (props.color ? props.color : colors.moss.base)};
    transform-origin: left center;
  }
  &:before {
    transition: width 0.2s ${easing.inOutStrong};
    width: 10px;
    bottom: -1px;
  }
  &:after {
    height: 1px;
  }
`

export const underlinedTextLinkHoverStyle = css`
  &:before {
    width: 100%;
  }
`

const UnderlinedTextLink = styled.a`
  ${underlinedTextLinkStyle};
  &:hover {
    ${underlinedTextLinkHoverStyle}
  }
  ${props => props.extraStyle};
`

export default UnderlinedTextLink
