import styled, { css } from 'styled-components'
import { mediaMax } from '@/styles/layout'

export const Group = styled.section`
  position: relative;
  ${props =>
    props.light &&
    css`
      padding-bottom: min(75px, 2.5vw);
      padding-top: 1px;
      &:not(:last-of-type) {
        margin-bottom: 100px;
      }
    `}
  &:last-of-type {
    padding-bottom: 80px;
    ${mediaMax(
      'small',
      css`
        padding-bottom: 60px;
      `
    )}
  }
`
