import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { mediaMax } from '@/styles/layout'
import { Row } from '@/components/common/Grid/Row'

export const ImageGalleryContainer = styled.div`
  margin-top: 70px;
  margin-bottom: 180px;
  ${props =>
    props.single &&
    css`
      margin-top: 70px;
      margin-bottom: 120px;
    `};
`

export const ImageRow = styled(Row)`
  align-items: flex-start;
`

export const ImageColumn = styled.div`
  ${props => column(props.columns, props.offset)};
  position: relative;
  ${mediaMax(
    'small',
    css`
      ${column(10, 0)};
    `
  )}
`
