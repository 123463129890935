import styled, { css } from 'styled-components'
import { colors } from '@/styles/color'

export const textInputStyle = css`
  border: none;
  border-bottom: 1px solid ${colors.white};
  background-color: transparent;
  color: ${colors.white};
  padding: 16px 8px;
  line-height: 1.2em;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${colors.moss.dark} inset;
    background: transparent;
    -webkit-text-fill-color: white;
  }
  &::placeholder {
    color: ${colors.grey.base};
  }
  &:focus {
    outline: none;
    border-color: #fff;
  }
`

export const Input = styled.input`
  ${textInputStyle};
  ${props => props.extraStyle}
`
