import styled, { css } from 'styled-components'
import { column } from '@/styles/grid'
import { colors } from '@/styles/color'
import { mediaMax } from '@/styles/layout'
import { dynamicFontSize, headlineStyle } from '@/styles/text'

export const LongTextContainer = styled.div`
  position: relative;
  color: ${colors.moss.base};
  overflow: hidden;
  margin: 50px 0;
`

export const LongTextsContainer = styled.div`
  ${column(6, 2)};
  padding: 50px 0;
  strong {
    font-weight: 500;
  }
  ${mediaMax(
    'small',
    css`
      ${column(8, 1)}
    `
  )}
`

export const longTextHeadlineStyle = css`
  font-size: 38px;
  ${headlineStyle};
  margin-block-start: 3em;
  &:first-of-type {
    margin-block-start: 1.8em;
  }
  ${mediaMax(
    'small',
    css`
      /*${props => dynamicFontSize('38px', props.children, 80)};*/
    `
  )}
`

export const Headline2 = styled.h2`
  ${longTextHeadlineStyle};
`

export const Headline3 = styled.h3`
  ${longTextHeadlineStyle};
`

export const LinkContainer = styled.div``
