import Linky from '@/components/common/Linky'
import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import {
  JobsContainer,
  JobsColumn,
  JobContainer,
  JobTitle,
  BottomRow,
  EmploymentType,
  JobLocation,
  JobsHeadline,
} from './styles'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const Jobs = ({ module }) => {
  const moduleInViewport = useViewport()
  const { headline } = module

  return (
    <JobsContainer>
      <MaxWidth>
        <Row>
          <JobsColumn>
            <JobsHeadline>
              <strong>{headline}</strong>
            </JobsHeadline>
            {module.jobs &&
              module.jobs.map((job, index) => {
                return (
                  <Linky to={job} key={job.id}>
                    <JobContainer inViewport={moduleInViewport} index={index}>
                      <JobTitle>{job.shortTitle ? job.shortTitle : job.title}</JobTitle>
                      <BottomRow>
                        <EmploymentType>{job.employmentType}</EmploymentType>
                        <JobLocation index={index} inViewport={moduleInViewport}>
                          {job.location}
                        </JobLocation>
                      </BottomRow>
                    </JobContainer>
                  </Linky>
                )
              })}
          </JobsColumn>
        </Row>
      </MaxWidth>
    </JobsContainer>
  )
}

export default Jobs
