import MaxWidth from '@/components/common/MaxWidth'
import { Row } from '@/components/common/Grid/Row'
import { IntroContainer, UpperContainer, LowerContainer, Headline, Subline, Paragraph } from './styles'
import Markdown from 'markdown-to-jsx'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'

const Intro = ({ module }) => {
  const moduleInViewport = useViewport()
  const { headline, copy, subline } = module

  return (
    <IntroContainer>
      <MaxWidth>
        <Row>
          <UpperContainer>
            <Headline inViewport={moduleInViewport}>{headline}</Headline>
            {subline && (
              <Subline inViewport={moduleInViewport}>
                <Markdown>{subline || ''}</Markdown>
              </Subline>
            )}
          </UpperContainer>
        </Row>
        {copy && (
          <Row>
            <LowerContainer>
              <Paragraph inViewport={moduleInViewport}>{copy}</Paragraph>
            </LowerContainer>
          </Row>
        )}
      </MaxWidth>
    </IntroContainer>
  )
}

export default Intro
