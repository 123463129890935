import styled, { css } from 'styled-components'
import { colors } from '@/styles/color'
import { extraSmall, mediaMax, medium } from '@/styles/layout'

export const CopyrightLabelContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, ${colors.blue.dark}70, ${colors.blue.dark}55);
  backdrop-filter: blur(8px);
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  max-width: 100%;
  ${props => props.extraStyle};
  color: ${colors.white};
`

export const LineContainer = styled.div`
  display: inline-block;
  height: 14px;
  position: relative;
  vertical-align: middle;
  margin-right: 7px;
  ${extraSmall(css`
    display: none;
  `)};
`

export const Line = styled.div`
  width: 24px;
  height: 1px;
  background-color: ${colors.white};
  position: relative;
  top: calc(50% - 1px);
  ${medium(css`
    width: 18px;
  `)}
  ${mediaMax(
    'small',
    css`
      width: 14px;
    `
  )};
`
