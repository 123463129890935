import { ImageGalleryContainer, ImageRow, ImageColumn } from './styles'
import MaxWidth from '@/components/common/MaxWidth'
import { useViewport } from '@/components/Pages/Page/ViewportDetector'
import ImageBlock from './ImageBlock'
import { useMediaLayerStore } from '@/service/MediaLayerService'
import { useMemo } from 'react'

const singleColumn = [
  {
    offset: 0,
    columns: 10,
    images: [{ aspectRatio: 0.34, index: 0 }],
  },
]

const imageColumns = [
  { offset: 1, columns: 6, images: [{ aspectRatio: 0.56, index: 0 }] },
  { offset: 2, columns: 3, images: [{ aspectRatio: 1.333, index: 1 }] },
  { offset: 0.5, columns: 4.5, images: [{ aspectRatio: 0.56, index: 2 }] },
  { offset: 1, columns: 2, images: [{ aspectRatio: 1, index: 3 }] },
  { offset: 0.5, columns: 5.5, images: [{ aspectRatio: 0.56, index: 4 }] },
  { offset: 1, columns: 4, images: [{ aspectRatio: 1.333, index: 5 }] },
  {
    offset: 0.5,
    columns: 4.5,
    images: [
      { aspectRatio: 0.56, index: 6 },
      { aspectRatio: 1.333, index: 7, width: 4.5 / 10 },
    ],
  },
]

const ImageGallery = ({ module }) => {
  const moduleInViewport = useViewport()

  const { images } = module

  const templateLength = useMemo(() => {
    let count = 0
    imageColumns.forEach(ic => {
      count += ic.images.length
    })
    return count
  }, [])

  const imageChunks = useMemo(() => {
    const chunks = []
    images.forEach((image, imageIndex) => {
      if (chunks.length === 0 || chunks[chunks.length - 1].length === templateLength) {
        chunks.push([])
      }
      chunks[chunks.length - 1].push(image)
    })
    return chunks
  }, [images, templateLength])

  const handleImageClick = index => {
    useMediaLayerStore.getState().openMedia(
      images.map(image => {
        return { image: image }
      }),
      index
    )
  }

  const singleImage = images.length === 1
  const columns = singleImage ? singleColumn : imageColumns

  return (
    <ImageGalleryContainer single={singleImage}>
      <MaxWidth>
        {imageChunks.map((chunk, chunkIndex) => {
          return (
            <ImageRow key={chunkIndex}>
              {columns.slice(0, chunk.length).map((column, columnIndex) => {
                return (
                  <ImageColumn key={columnIndex} {...column}>
                    {column.images.map((cImage, cIIndex) => {
                      const imageIndex = chunkIndex * templateLength + cImage.index
                      if (!images[imageIndex]) return null
                      const desktopSizeFactor = (column.columns / 10) * 1.1 //1.1 is for some additional buffer
                      return (
                        <ImageBlock
                          image={images[imageIndex]}
                          key={cIIndex}
                          clickHandler={() => handleImageClick(imageIndex)}
                          column={column}
                          cImage={cImage}
                          desktopSizeFactor={desktopSizeFactor}
                        />
                      )
                    })}
                  </ImageColumn>
                )
              })}
            </ImageRow>
          )
        })}
      </MaxWidth>
    </ImageGalleryContainer>
  )
}

export default ImageGallery
