import styled, { css } from 'styled-components'
import { colors } from '@/styles/color'
import { center, maxWidth } from '@/styles/layout'
import { appear } from '@/styles/animation'

const imageMargin = `min(${maxWidth / 40}px, 2.5vw)`
const doubleImageMargin = `min(${maxWidth / 20}px, 5vw)`

export const ImageContainer = styled.div`
  position: relative;
  padding-top: ${props => props.aspectRatio * 100}%;
  margin-top: ${imageMargin};
  margin-bottom: ${imageMargin};
  cursor: zoom-in;
  overflow: hidden;
  background-color: ${colors.moss.veryDark};
  ${props =>
    props.width &&
    css`
      width: ${props.width * 100}%;
      padding-top: ${props => props.aspectRatio * 100 * props.width}%;
    `};
  &:not(:first-of-type) {
    margin-top: ${doubleImageMargin};
  }
  ${appear({ delay: 0.5 })};
`

export const imageStyle = css`
  object-fit: cover;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  position: absolute;
  ${center};
`
